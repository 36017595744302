@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Poppins:wght@500&display=swap');

:root {
  --textcolor: #050315;
  --bgcolor: #fbfbfe;
  --primcolor: #10c854;
  --secondcolor: #dac9fd;
  --accentcolor: #ff5b3b;
  --titlefont: 'Concert One', sans-serif;
  --mainfont: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
