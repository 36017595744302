.main {
    background-color: var(--bgcolor);
    width: 100%;
    height: 100lvh;
}

.main_TopBar {
    background-color: var(--secondcolor);
    padding: 1rem;
    display: flex;
    align-items: center;
}

.main_BarTitle {
    font-size: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    user-select: none;
    margin-right: auto;
}

.main_TitleText {
    font-family: var(--titlefont);
}

.main_TitleText span:first-child {
    color: var(--primcolor);
}

.main_TitleText span:last-child {
    color: var(--accentcolor);
}

.main_MainTitle {
    font-size: 6rem;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 1rem;
    user-select: none;
}

.main_MainDesc {
    font-size: 1rem;
    max-width: 48rem;
    padding: 2rem;
    margin: auto;
    text-align: justify;
    color: var(--textcolor);
    font-family: var(--mainfont);
}

.main_Link {
    font-family: var(--mainfont);
    color: var(--textcolor);
    background-color: var(--primcolor);
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-decoration: none;
    border: none;
}

.main_Link:active {
    transform: scale(0.95);
}

.main_ContactForm button.main_Link {
    margin: 0;
    margin-top: 1rem;
    cursor: pointer;
}

.main_Link:last-child {
    background-color: var(--accentcolor);
}

.main_ContactForm {
    background-color: var(--secondcolor);
    border-radius: 1.5rem;
    width: 32rem;
    max-width: calc(100% - 6rem);
    margin: auto;
    margin-top: 4rem;
    padding: 1rem;
    font-family: var(--mainfont);
    text-align: right;
}

.main_ContactForm h1 {
    font-size: 2rem;
    margin: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
    font-family: var(--titlefont);
}

.main_FormInput {
    font-size: 1rem;
    background-color: rgba(255,255,255,0.5);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.main_FormInput div {
    display: inline-block;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-right: 2px solid var(--secondcolor);
    user-select: none;
    width: 8rem;
}

.main_FormInput input {
    outline: none;
    border: none;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-family: var(--mainfont);
    width: 100%;
    background-color: transparent;
}