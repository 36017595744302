@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.material-symbols-outlined {
    font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
}

.material-symbols-outlined.filled {
    font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
}

.quiz button {
    -webkit-tap-highlight-color: transparent;
}

.quiz {
    background-color: #F0F0F0;
}

.quiz_page {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    gap: 4rem;
    padding: 1rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    font-family: var(--mainfont);
    max-width: 64rem;
    align-content: center;
    align-items: center;
    margin: auto;
    background-color: var(--bgcolor);
    box-shadow: 0 0 1rem rgba(0,0,0,0.1);
    min-height: calc(100lvh - 4rem);

    --labelfont: "Open Sans", sans-serif;
    --input-disabled: #EEEEEE;
}

.quiz_page>h1 {
    font-size: 2rem;
    margin: 0;
    width: calc(100% - 4rem);
    font-family: var(--labelfont);
}

.quiz_page p {
    margin: 1rem;
}

.quiz_Button {
    font-family: var(--mainfont);
    color: var(--textcolor);
    background-color: var(--primcolor);
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-decoration: none;
    border: none;
}

.quiz_Button:disabled {
    background-color: var(--input-disabled);
}

.quiz_Button:enabled:active, .quiz_QuizEditButton:enabled:active {
    transform: scale(0.95);
}

.quiz_page>.quiz_Button {
    align-self: flex-end;
}

.quiz_QuizButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.quiz_QuizButtons>.quiz_Button {
    margin-right: 1rem;
}

.quiz_Question {
    display: inline-flex;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.quiz_Question>button, .quiz_QuizEditButton {
    user-select: none;
    border: none;
    padding: 0.5rem;
    background-color: transparent;
    outline: none;
    color: var(--bgcolor);
    border-radius: 1.5rem;
}

.quiz_Question>button:enabled:active {
    transform: scale(0.9);
}

.quiz_Question>p {
    padding: 1rem;
    width: 18rem;
    margin: 0;
}

.quiz_QuestionContainer {
    background-color: var(--secondcolor);
    padding: 1rem;
    display: inline-flex;
    border-radius: 1.5rem;
    flex-wrap: wrap;
    flex-direction: column;
}

.quiz_QuestionContainer>div {
    width: 18rem;
    background-color: var(--bgcolor);
    border-radius: 0.5rem;
}

.quiz_QuestionContainer>h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.5rem;
    font-family: var(--labelfont);
}

.quiz_TextTemplate {
    font-size: 1rem;
    margin: 1rem;
}

.quiz_TextTemplateInput {
    font-size: inherit;
    font-family: inherit;
    border: none;
    background-color: var(--secondcolor);
    border-radius: 0.25rem;
    width: 3rem;
    text-align: center;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

.quiz_TextTemplateInput:disabled {
    background-color: var(--input-disabled);
}

.quiz_MultipleChoice {
    margin-bottom: 1rem;
}

.quiz_MultipleChoice label {
    user-select: none;
    font-size: 1rem;
    font-family: var(--mainfont);
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}

.quiz_MultipleChoice label:has(input:enabled):active {
    background-color: var(--secondcolor);
    transform: scale(0.98);
}

.quiz_MultipleChoice input {
    margin-right: 1rem;
    accent-color: var(--textcolor);
}

.quiz_TextResponse {
    display: flex;
    flex-direction: column;
}

.quiz_TextResponse textarea, .quiz_TextResponse input {
    font-family: inherit;
    font-size: inherit;
    resize: none;
    margin: 1rem;
    margin-top: 0;
    flex-basis: 100%;
    outline: none;
    border: none;
    background-color: rgba(0,0,0,0.1);
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.quiz_TextResponse textarea:focus, .quiz_TextResponse input:focus {
    background-color: rgba(0,0,0,0.05);
}

.quiz_TextResponse textarea:disabled, .quiz_TextResponse input:disabled {
    background-color: var(--input-disabled);
}

.quiz_Prompter {
    font-family: inherit;
    font-size: inherit;
    resize: none;
    margin: 1rem;
    margin-top: 0;
    flex-basis: 100%;
    outline: none;
    border: 4px solid rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.05);
    border-radius: 1rem;
    padding: 0.5rem;
    transition: border-color 0.1s, box-shadow 0.1s;
}

.quiz_Prompter:focus {
    border-color: var(--primcolor);
    box-shadow: 0 0.25rem 0.5rem var(--primcolor);
}

.quiz_promptpage {
    gap: 1rem;
}

.quiz_promptpage h1 {
    width: unset;
    margin-bottom: 2rem;
}

.quiz_promptpage textarea {
    margin: 0;
    width: 30rem;
    max-width: calc(100% - 2rem);
}

.quiz_QuizGrade {
    color: var(--textcolor);
}

.quiz_QuizGrade>span:nth-child(1) {
    font-size: 2rem;
    vertical-align: top;
}

.quiz_QuizGrade>span:nth-child(2) {
    font-size: 3rem;
}

.quiz_QuizGrade>span:nth-child(3) {
    font-size: 2rem;
    vertical-align: bottom;
}

.quiz_QuizGrade>span:nth-child(4) {
    font-size: 3rem;
    vertical-align: bottom;
    margin-left: 3rem;
}

.quiz_QuizGrade>span:nth-child(5) {
    font-size: 3rem;
    vertical-align: bottom;
    margin-left: 3rem;
    font-weight: bold;
    text-outline: 0.5rem solid var(--accentcolor);
}

.quiz_ErrorBanner {
    background-color: #ffecec;
    padding-left: 1rem;
    margin-top: 2rem;
    align-self: stretch;
    border-radius: 0.5rem;
    user-select: none;
    max-width: calc(100% - 1rem);
    width: 30.5rem;
    display: flex;
    /*animation-name: lower_fadein;*/
    /*animation-duration: 0.25s;*/
}

.quiz_ErrorBanner:nth-child(2) {
    align-self: center;
    position: sticky;
    top: 1rem;
    margin-top: 0;
}

.quiz_ErrorBanner>div {
    color: red;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 2rem;
}

.quiz_QuizEditButtonContainer {
    margin-bottom: -4rem;
}

.quiz_QuizEditButton {
    position: fixed;
    top: 2rem;
    right: 2rem;
    color: var(--textcolor);
    background-color: rgba(180, 180, 180, 0.5);
    box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,0.1);
    backdrop-filter: blur(16px);
}

.quiz_QuizEditButtonContainer>dialog {
    display: flex;
    flex-direction: column;
    outline: none;
    border: none;
    width: 24rem;
    max-width: calc(100% - 4rem);
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.2);
}

.quiz_QuizEditButtonContainer>dialog[hidden] {
    display: none;
}

.quiz_QuizEditButtonContainer>dialog::backdrop {
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(2px);
}

.quiz_QuizEditButtonContainer>dialog>button {
    align-self: flex-end;
}

.quiz_QuizEditButtonContainer>dialog>textarea {
    margin-left: 0;
    margin-right: 0;
}

.quiz_QuizEditButtonContainer>dialog>p {
    margin-top: 0;
}

@keyframes lower_fadein {
    from {
        opacity: 0;
        transform: translate(0, -1rem);
    }
    to {
        opacity: 1;
    }
}